import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $: any;
@Component({
  selector: 'app-connectorcolors',
  templateUrl: './connectorcolors.component.html',
  styleUrls: ['./connectorcolors.component.sass']
})
export class ConnectorcolorsComponent implements OnInit {

    loading: boolean = false;
    colors: any = []
    color: any = {
        name: '',
        hex: ''
    } 
    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true
        this.rest.getElement('ConnectorColor').subscribe(colors => {
            this.colors = colors
            this.loading = false
        })
    }

    saveColor(){
        this.rest.saveColor(this.color).subscribe(ata => {
            this.color = {
                name: '',
                hex: ''
            }
            this.search()
        })
    }

    editColor(color){
        this.color = color;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    removeColor(color){
        this.rest.removeColor(color.id).subscribe(data => {
            this.search()
        })
    }

}
