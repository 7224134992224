import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv'
import * as moment from 'moment'
declare let $:any
@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.sass']
})
export class SessionsComponent implements OnInit {

    loading: any = false;
    items: any = []

    Years: any = [
        {
            id: 2019,
            name: 2019
        },
        {
            id: 2020,
            name: 2020
        },
        {
            id: 2021,
            name: 2021
        },
        {
            id: 2022,
            name: 2022
        },
        {
            id: 2023,
            name: 2023
        },
        {
            id: 2024,
            name: 2024
        },
        {
            id: 2025,
            name: 2025
        }
    ]

    Months: any = [
        {
            id: '01',
            name: 'January'
        },
        {
            id: '02',
            name: 'February'
        },
        {
            id: '03',
            name: 'March'
        },
        {
            id: '04',
            name: 'April'
        },
        {
            id: '05',
            name: 'May'
        },
        {
            id: '06',
            name: 'June'
        },
        {
            id: '07',
            name: 'July'
        },
        {
            id: '08',
            name: 'August'
        },
        {
            id: '09',
            name: 'September'
        },
        {
            id: '10',
            name: 'October'
        },
        {
            id: '11',
            name: 'November'
        },
        {
            id: '12',
            name: 'December'
        }
    ]

    searchData: any = {
        year: moment().format('YYYY'),
        month: moment().format("MM")
    }

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getSessions(this.searchData).subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true
                });
                this.loading = false
            }, 1000)
        })
    }

    searchSessions(){
        this.search()
    }

    exportAnalitics(){
        this.rest.getAnalytics(this.searchData).subscribe((data: any) => {
            this.loading = false
            let json = data.map(x => {
                x =  {
                    'Date': moment(x.date).format('YYYY-MM-DD'),
                    'Time': moment(x.date).format('hh:mm A'),
                    'Dealer Name': x.DealerName,
                    'Dealer Code': x.DlrCode,
                    'User Name': x.name,
                    'Email': x.email,
                    'Year Model': x.yearmodelname,
                    'Project': x.projectname,
                    'Connector Color': x.connectorcolorname,
                    'Number of Poles': x.numberofpolesname,
                    'Bosch Number': x.BoschPartNo,
                    'Connector Type': x.Connector,
                    'Terminal Bosch Number': x.CTW1No,
                    'Terminal Type': x.TypeTerminal,
                    'Tools': (x.tools) ? 'Yes' : 'No',
                    'Terminal': (x.terminal) ? 'Yes' : 'No',
                    'Video' : (x.video) ? 'Yes' : 'No'
                }
                
                return x
            })
            new Angular5Csv(json, 'Analytics', {headers: [
                'Date',
                'Time',
                'Dealer Name',
                'Dealer Code',
                'User Name',
                'Email',
                'Year Model',
                'Project',
                'Connector Color',
                'Number of Poles',
                'Bosch Number',
                'Connector Type',
                'Terminal Bosch Number',
                'Terminal Type',
                'Tools',
                'Terminal',
                'Video',
            ]})
        })
    }

    export(){
        let json = this.items.map(x => {
            x =  {
                DealerName: x.DealerName,
                DlrCode: x.DlrCode,
                yearmodel: x.name,
                project: x.email,
                date: moment(x.created_at).format('LL')
            }
            
            return x
        })
        new Angular5Csv(json, 'Sessions', {headers: [
            "Dealer's name",
            "Dealer's code",
            "Technician's name",
            "Technician's email",
            "Date"
        ]})
    }

}
