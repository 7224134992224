import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $:any
@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.sass']
})
export class DealersComponent implements OnInit {

    item: any = {
       Region: '',
       Area: '',
       District: '',
       DlrCode: '',
       DealerName: '',
       FacilityAddress: '',
       FacilityCity: '',
       FacilityState: '',
       FacilityZip: '',
       Telephone: '',
       Fax: '',
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getDealers().subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [11] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    save(){
        this.rest.saveElement(this.item, 'Dealer').subscribe(ata => {
            this.item = {
                Region: '',
                Area: '',
                District: '',
                DlrCode: '',
                DealerName: '',
                FacilityAddress: '',
                FacilityCity: '',
                FacilityState: '',
                FacilityZip: '',
                Telephone: '',
                Fax: '',
            }
            this.search()
        })
    }

    edit(item){
        this.item = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'Dealer').subscribe(data => {
            this.search()
        })
    }
}
