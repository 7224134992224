import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $: any;
@Component({
  selector: 'app-ctw1',
  templateUrl: './ctw1.component.html',
  styleUrls: ['./ctw1.component.sass']
})
export class Ctw1Component implements OnInit {

    item: any = {
        name: '',
        description: '',
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getElement('CTW1No').subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [1] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    save(){
        this.rest.saveElement(this.item, 'CTW1No').subscribe(ata => {
            this.item = {
                name: ''
            }
            this.search()
        })
    }

    edit(item){
        this.item = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'CTW1No').subscribe(data => {
            this.search()
        })
    }

}
