import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $: any
@Component({
  selector: 'app-polesnumber',
  templateUrl: './polesnumber.component.html',
  styleUrls: ['./polesnumber.component.sass']
})
export class PolesnumberComponent implements OnInit {



    item: any = {
        name: ''
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getElement('Numberofpole').subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [1] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    save(){
        this.rest.saveElement(this.item, 'Numberofpole').subscribe(ata => {
            this.item = {
                name: ''
            }
            this.search()
        })
    }

    edit(item){
        this.item = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'Numberofpole').subscribe(data => {
            this.search()
        })
    }
}
