import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $:any
@Component({
  selector: 'app-connectors',
  templateUrl: './connectors.component.html',
  styleUrls: ['./connectors.component.sass']
})
export class ConnectorsComponent implements OnInit {
    item: any = {
        name: '',
        video_rrt: '',
        video_trt: ''
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    fileChangeEvent(event: any, image?): void {
        var FR= new FileReader();
        let self = this;
    
        FR.addEventListener("load", function(e: any) {
            self.item[image] = e.target.result
        }); 
        FR.readAsDataURL( $('#video')[0].files[0] );
    }


    selectVideo(image){
        $('#video-' + image).trigger('click')
    }

    search(){
        this.loading = true;
        this.rest.getElement('Connector').subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [1] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    save(){
        this.rest.saveElement(this.item, 'Connector').subscribe(ata => {
            this.item = {
                name: '',
                video_rrt: '',
                video_trt: ''
            }
            this.search()
        })
    }

    edit(item){
        this.item = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'Connector').subscribe(data => {
            this.search()
        })
    }

}
