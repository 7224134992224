import { Component, OnInit, ChangeDetectorRef, NgZone} from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router, ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.sass']
})
export class ItemComponent implements OnInit {

    search: any = {
        OutInsid: null,
        ConnectorcolorId: 1,
        NumberOfPolesId: 1,
        MaleFemaleId: null,
        connector: ''
    }
    element: any = {
        id: null,
        YearModelId:0, 
        ProjectId:0, 
        HarnessId:0, 
        NumberId:0, 
        SystemId:0, 
        ConnectorId:0, 
        NoteId:0, 
        OutInsideCarId:0, 
        Group2Id:0, 
        Group3Id:0, 
        Group4Id:0, 
        BoschPartNoId:0, 
        ConnectorColorId:0, 
        NumberOfPolesId:0, 
        MaleFemale:0, 
        CTW1NoId:0, 
        CTW2NoId:0, 
        ToolRetainerId:0, 
        ToolTerminalId:0, 
        STGId:0, 
        NissanTrayLocationsId:0, 
        InfinityTrayLocationsId:0, 
        TypeTerminalId:0, 
        Extras2Id:0, 
        NissanTrayLocationsTerminalId:0, 
        InfinitiTrayLocationsTerminalId:0
    }
    item: any = {}
    config: any = {
        displayKey:"name", //if objects array passed which key to be displayed defaults to description
        search:true, //true/false for the search functionlity defaults to false,
        height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
        placeholder:'Select' ,// text to be displayed when no item is selected defaults to Select,
        moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
        noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
        searchPlaceholder:'Search', // label thats displayed in search input,
        searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
        bindKey: 'id'
    }
    loading: boolean = false;
    inventory: any = []
    ConnectorColor: any = []
    Numberofpole: any = []
    Project: any = []
    YearModel: any = []
    Harness: any = []
    Numbers: any = []
    System: any = []
    Connector: any = []
    Note: any = []
    Outinsidecar: any = []
    Group2: any = []
    Group3: any = []
    Group4: any = []
    BoschPartNo: any = []
    MaleFemale: any = []
    CTW1No: any = []
    CTW2NO: any = []
    Retainertool: any = []
    Terminaltool: any = []
    STG: any = []
    Nissantraylocation: any = []
    Infinitytraylocation: any = []
    TypeTerminal: any = []
    Extra2: any = []
    NissanTrayLocationsTerminal: any = []
    InfinitiTrayLocationsTerminal: any = []

    constructor(public rest: RestService, private chRef: ChangeDetectorRef, private zone: NgZone, private route: ActivatedRoute) { }

    async ngOnInit() {
        $.fn.dataTable.ext.errMode = 'none';
        this.loading = true;

        this.route.params.subscribe(params => {
            console.log(params)
            this.rest.getAll().subscribe((data: any) => {
                this.Project = data.Project.sort((a, b)  => a.name - b.name)
                this.YearModel = data.YearModel.sort((a, b)  => a.name - b.name)
                this.Harness = data.Harness.sort((a, b)  => a.name - b.name)
                this.Numbers = data.Numbers.sort((a, b)  => a.name - b.name)
                this.System = data.System.sort((a, b)  => a.name - b.name)
                this.Connector = data.Connector.sort((a, b)  => a.name - b.name)
                this.Note = data.Note.sort((a, b)  => a.name - b.name)
                this.Outinsidecar = data.Outinsidecar.sort((a, b)  => a.name - b.name)
                this.Group2 = data.Group2.sort((a, b)  => a.name - b.name)
                this.Group3 = data.Group3.sort((a, b)  => a.name - b.name)
                this.Group4 = data.Group4.sort((a, b)  => a.name - b.name)
                this.BoschPartNo = data.BoschPartNo.sort((a, b)  => a.name - b.name).filter(x => x.active == 1)
                this.ConnectorColor = data.ConnectorColor.sort((a, b)  => a.name - b.name).filter(x => x.active == 1)
                this.Numberofpole = data.Numberofpole.sort((a, b)  => a.name - b.name).filter(x => x.active == 1)
                this.MaleFemale = data.MaleFemale.sort((a, b)  => a.name - b.name)
                this.CTW1No = data.CTW1No.sort((a, b)  => a.name - b.name)
                this.CTW2NO = data.CTW2NO.sort((a, b)  => a.name - b.name)
                this.Retainertool = data.Retainertool.sort((a, b)  => a.name - b.name)
                this.Terminaltool = data.Terminaltool.sort((a, b)  => a.name - b.name)
                this.STG = data.STG.sort((a, b)  => a.name - b.name)
                this.Nissantraylocation = data.Nissantraylocation.filter(x => x.type == 1).sort((a, b)  => a.name - b.name)
                this.Infinitytraylocation = data.Infinitytraylocation.filter(x => x.type == 1).sort((a, b)  => a.name - b.name)
                this.NissanTrayLocationsTerminal = data.Nissantraylocation.filter(x => x.type == 2).sort((a, b)  => a.name - b.name)
                this.InfinitiTrayLocationsTerminal = data.Infinitytraylocation.filter(x => x.type == 2).sort((a, b)  => a.name - b.name)
                this.TypeTerminal = data.TypeTerminal.sort((a, b)  => a.name - b.name)
                this.Extra2 = data.Extra2

                if(params.id !== 'new'){
                    this.rest.getItem(params.id).subscribe((item: any) => {
                        this.element = {
                            id: item.id,
                            YearModelId: this.YearModel.filter(x => x.id == item.YearModelId)[0],
                            ProjectId: this.Project.filter(x => x.id == item.ProjectId)[0],
                            HarnessId: this.Harness.filter(x => x.id == item.HarnessId)[0],
                            NumberId: this.Numbers.filter(x => x.id == item.NumberId)[0],
                            SystemId: this.System.filter(x => x.id == item.SystemId)[0],
                            ConnectorId: this.Connector.filter(x => x.id == item.ConnectorId)[0],
                            NoteId: this.Note.filter(x => x.id == item.NoteId)[0],
                            OutInsideCarId: this.Outinsidecar.filter(x => x.id == item.OutInsideCarId)[0],
                            Group2Id: this.Group2.filter(x => x.id == item.Group2Id)[0],
                            Group3Id: this.Group3.filter(x => x.id == item.Group3Id)[0],
                            Group4Id: this.Group4.filter(x => x.id == item.Group4Id)[0],
                            BoschPartNoId: this.BoschPartNo.filter(x => x.id == item.BoschPartNoId)[0],
                            ConnectorColorId: this.ConnectorColor.filter(x => x.id == item.ConnectorColorId)[0],
                            NumberOfPolesId: this.Numberofpole.filter(x => x.id == item.NumberOfPolesId)[0],
                            MaleFemale: this.MaleFemale.filter(x => x.id == item.MaleFemale)[0],
                            CTW1NoId: this.CTW1No.filter(x => x.id == item.CTW1NoId)[0],
                            CTW2NoId: this.CTW2NO.filter(x => x.id == item.CTW2NoId)[0],
                            ToolRetainerId: this.Retainertool.filter(x => x.id == item.ToolRetainerId)[0],
                            ToolTerminalId: this.Terminaltool.filter(x => x.id == item.ToolTerminalId)[0],
                            STGId: this.STG.filter(x => x.id == item.STGId)[0],
                            NissanTrayLocationsId: this.Nissantraylocation.filter(x => x.id == item.NissanTrayLocationsId)[0],
                            InfinityTrayLocationsId: this.Infinitytraylocation.filter(x => x.id == item.InfinityTrayLocationsId)[0],
                            TypeTerminalId: this.TypeTerminal.filter(x => x.id == item.TypeTerminalId)[0],
                            Extras2Id: this.Extra2.filter(x => x.id == item.Extras2Id)[0],
                            NissanTrayLocationsTerminalId: this.NissanTrayLocationsTerminal.filter(x => x.id == item.NissanTrayLocationsTerminalId)[0],
                            InfinitiTrayLocationsTerminalId: this.InfinitiTrayLocationsTerminal.filter(x => x.id == item.InfinitiTrayLocationsTerminalId)[0],
                        }
                        Object.keys(this.element).map(x => {
                            if(this.element[x] == undefined){
                                this.element[x] = null;
                            }else{
                                this.element[x] = this.element[x].name
                            }
                        })
                        this.element.id = item.id
                        this.item = item;
                        this.loading = false;
                        console.log(this.element, this.item)
                    })
                }else{
                    this.loading = false;
                }
                
            })
        });
    }


    onChange(e, type){
        this.item[type] = e.value.id;
    }

    save(){
        this.loading = true;
        this.rest.saveIventory(this.item).subscribe((item: any) => {
            this.rest.getItem(item.id).subscribe((item: any) => {
                this.element = {
                    id: item.id,
                    YearModelId: this.YearModel.filter(x => x.id == item.YearModelId)[0],
                    ProjectId: this.Project.filter(x => x.id == item.ProjectId)[0],
                    HarnessId: this.Harness.filter(x => x.id == item.HarnessId)[0],
                    NumberId: this.Numbers.filter(x => x.id == item.NumberId)[0],
                    SystemId: this.System.filter(x => x.id == item.SystemId)[0],
                    ConnectorId: this.Connector.filter(x => x.id == item.ConnectorId)[0],
                    NoteId: this.Note.filter(x => x.id == item.NoteId)[0],
                    OutInsideCarId: this.Outinsidecar.filter(x => x.id == item.OutInsideCarId)[0],
                    Group2Id: this.Group2.filter(x => x.id == item.Group2Id)[0],
                    Group3Id: this.Group3.filter(x => x.id == item.Group3Id)[0],
                    Group4Id: this.Group4.filter(x => x.id == item.Group4Id)[0],
                    BoschPartNoId: this.BoschPartNo.filter(x => x.id == item.BoschPartNoId)[0],
                    ConnectorColorId: this.ConnectorColor.filter(x => x.id == item.ConnectorColorId)[0],
                    NumberOfPolesId: this.Numberofpole.filter(x => x.id == item.NumberOfPolesId)[0],
                    MaleFemale: this.MaleFemale.filter(x => x.id == item.MaleFemale)[0],
                    CTW1NoId: this.CTW1No.filter(x => x.id == item.CTW1NoId)[0],
                    CTW2NoId: this.CTW2NO.filter(x => x.id == item.CTW2NoId)[0],
                    ToolRetainerId: this.Retainertool.filter(x => x.id == item.ToolRetainerId)[0],
                    ToolTerminalId: this.Terminaltool.filter(x => x.id == item.ToolTerminalId)[0],
                    STGId: this.STG.filter(x => x.id == item.STGId)[0],
                    NissanTrayLocationsId: this.Nissantraylocation.filter(x => x.id == item.NissanTrayLocationsId)[0],
                    InfinityTrayLocationsId: this.Infinitytraylocation.filter(x => x.id == item.InfinityTrayLocationsId)[0],
                    TypeTerminalId: this.TypeTerminal.filter(x => x.id == item.TypeTerminalId)[0],
                    Extras2Id: this.Extra2.filter(x => x.id == item.Extras2Id)[0],
                    NissanTrayLocationsTerminalId: this.NissanTrayLocationsTerminal.filter(x => x.id == item.NissanTrayLocationsTerminalId)[0],
                    InfinitiTrayLocationsTerminalId: this.InfinitiTrayLocationsTerminal.filter(x => x.id == item.InfinitiTrayLocationsTerminalId)[0],
                }
                Object.keys(this.element).map(x => {
                    if(this.element[x] == undefined){
                        this.element[x] = null;
                    }else{
                        this.element[x] = this.element[x].name
                    }
                })
                this.element.id = item.id
                this.item = item;
                this.loading = false;
                console.log(this.element, this.item)
            })
        })
    }

    searchInventory(){
        this.loading = true;
        this.rest.getInventory(this.search).subscribe((inventory: any) => {
            inventory = inventory.map(item => {
                item.ConnectorImage = this.rest.api + item.ConnectorImage
                return item
            })
            $('table').dataTable().fnDestroy();
            this.inventory = inventory;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": false,
                    "columnDefs": [
                        { "orderable": false, "targets": [1,2,3,4,5,6,7] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

}
