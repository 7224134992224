import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { MomentModule } from 'ngx-moment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { AddItemComponent } from './pages/add-item/add-item.component';
import { ConnectorsComponent } from './pages/connectors/connectors.component';
import { ConnectorcolorsComponent } from './pages/connectorcolors/connectorcolors.component';
import { BoschpartnoComponent } from './pages/boschpartno/boschpartno.component';
import { SystemsComponent } from './pages/systems/systems.component';
import { TerminaltypeComponent } from './pages/terminaltype/terminaltype.component';
import { ModelyearComponent } from './pages/modelyear/modelyear.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NumbersComponent } from './pages/numbers/numbers.component';
import { PolesnumberComponent } from './pages/polesnumber/polesnumber.component';
import { RetainertoolsComponent } from './pages/retainertools/retainertools.component';
import { TerminaltoolsComponent } from './pages/terminaltools/terminaltools.component';
import { StgComponent } from './pages/stg/stg.component';
import { NissantraylocationsComponent } from './pages/nissantraylocations/nissantraylocations.component';
import { InfinitytraylocationsComponent } from './pages/infinitytraylocations/infinitytraylocations.component';
import { HarnessComponent } from './pages/harness/harness.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { RestService } from './services/rest.service';
import { HttpClientModule } from '@angular/common/http';
import { MediaComponent } from './pages/media/media.component';
import { ItemComponent } from './pages/item/item.component';
import { TermsComponent } from './pages/terms/terms.component';
import { DealersComponent } from './pages/dealers/dealers.component';
import { SessionsComponent } from './pages/sessions/sessions.component';
import { CommentsComponent } from './pages/comments/comments.component';
import { Ctw1Component } from './pages/ctw1/ctw1.component';
import { Ctw2Component } from './pages/ctw2/ctw2.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { FilterPipe } from './pages/messages/FilterPipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InventoryComponent,
    AddItemComponent,
    ConnectorsComponent,
    ConnectorcolorsComponent,
    BoschpartnoComponent,
    SystemsComponent,
    TerminaltypeComponent,
    ModelyearComponent,
    ProjectsComponent,
    NumbersComponent,
    PolesnumberComponent,
    RetainertoolsComponent,
    TerminaltoolsComponent,
    StgComponent,
    NissantraylocationsComponent,
    InfinitytraylocationsComponent,
    HarnessComponent,
    MediaComponent,
    ItemComponent,
    TermsComponent,
    DealersComponent,
    SessionsComponent,
    CommentsComponent,
    Ctw1Component,
    Ctw2Component,
    MessagesComponent,
    FilterPipe
  ],
  imports: [
    JwtModule.forRoot({
        config: {
          tokenGetter: function(){
            return '';
          }
        }
    }),
    NgxLoadingModule.forRoot({}),
    BrowserModule,
    AppRoutingModule,
    ColorPickerModule,
    FormsModule,
    ImageCropperModule,
    MomentModule,
    HttpClientModule,
    SelectDropDownModule
  ],
  providers: [AuthService, AuthGuardService, JwtHelperService, RestService],
  bootstrap: [AppComponent]
})
export class AppModule { }
