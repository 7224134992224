import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $:any;
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass']
})
export class ProjectsComponent implements OnInit {


    item: any = {
        name: '',
        type: 1,
        image: '',
        imageData: ''
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getElement('Project').subscribe((data: any) => {
            $('table').dataTable().fnDestroy();
            data = data.map(x => {
                x.image = this.rest.api + 'media/' + x.image
                return x;
            })
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [0,3] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    addImage(){
        $('#image').trigger('click')
        $('#image').on('change', () => {
            var fileReader = new FileReader();
            fileReader.onload = () => {
                this.item.imageData = fileReader.result;
            };
            fileReader.readAsDataURL($('#image').prop('files')[0]);
        });
    }

    save(){
        const formData = new FormData();
        if(this.item.id){
            formData.append('id', this.item.id)
        }
        formData.append('name', this.item.name)
        formData.append('types', this.item.type)
        formData.append('type', 'Project')
        formData.append('image', $('#image')[0].files[0])
        this.rest.saveElement(formData).subscribe(ata => {
            this.item = {
                name: ''
            }
            $('#image').val(null)
            this.search()
        })
    }

    edit(item){
        this.item = item;
        this.item.imageData = this.item.image
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'Project').subscribe(data => {
            this.search()
        })
    }
}
