import { PipeTransform, Pipe } from '@angular/core';
@Pipe({
    name: 'filterlist'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], term: string): any {
        // I am unsure what id is here. did you mean title?
        return items.filter(item => {
            let find = false;
            if(item.name){
                if(item['name'].toLowerCase().indexOf(term.toLowerCase()) !== -1){
                    find = true;
                }
            }
            if(item.email){
                if(item['email'].toLowerCase().indexOf(term.toLowerCase()) !== -1){
                    find = true;
                }
            }
            if(item.DealerName){
                if(item['DealerName'].toLowerCase().indexOf(term.toLowerCase()) !== -1){
                    find = true;
                }
            }
            if(item.DlrCode){
                if(item['DlrCode'].toLowerCase().indexOf(term.toLowerCase()) !== -1){
                    find = true;
                }
            }
            return find
        });
    }
}