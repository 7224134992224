import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

    submitted: boolean = false;
    tipo: any = 2
    credentials: any = {
        email: '',
        password: ''
    }

    errors: any = {
        email: null,
        password: null
    }

    constructor(public rest: RestService, public router: Router) {
        if(localStorage.getItem('token')){
            this.router.navigate([''])
        }
    }

    ngOnInit() {
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    login(){
        this.submitted = true;

        if(this.credentials.email == ''){
            this.errors.email = 'Please enter a valid email'
            return;
        }else{
            this.errors.email = null;
        }
        if(!this.validateEmail(this.credentials.email)){
            this.errors.email = 'Please enter a valid email'
            return;
        }else{
            this.errors.email = null;
        }
        if(this.credentials.password == ''){
            this.errors.password = "Please enter a valid password"
            return;
        }else{
            this.errors.password = null;
        }


        this.rest.login(this.credentials).subscribe((token: any) => {
            if(token.status == 206){
                if(token.error.uidField){
                    this.errors.email = 'This email is not registered'
                }else if(token.error.passwordField){
                    this.errors.password = "The password does not match the email account"
                }
            }else{
                if(this.credentials.email == 'chat@hrknissan.ddns.net'){
                    if(this.tipo == 2){
                        localStorage.setItem('token', token.access_token.token)
                        window.location.href = "https://hrknissan.ddns.net/messenger"
                    }else{
                        this.errors.email = 'This email is not registered'
                    }
                }else{
                    localStorage.setItem('token', token.access_token.token)
                    this.router.navigate([''])
                    window.location.reload()
                }
            }
        }, error => {
            this.errors.email = 'Please enter a valid email'
            this.errors.password = "Please enter a valid password"
        })

        
    }

}
