import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
declare let $: any;
@Component({
  selector: 'app-terminaltype',
  templateUrl: './terminaltype.component.html',
  styleUrls: ['./terminaltype.component.sass']
})
export class TerminaltypeComponent implements OnInit {

    item: any = {
        name: ''
    }
    loading: any = false;
    items: any = []

    constructor(public rest: RestService) { }

    ngOnInit() {
        this.search()
    }

    search(){
        this.loading = true;
        this.rest.getElement('TypeTerminal').subscribe(data => {
            $('table').dataTable().fnDestroy();
            this.items = data;
            setTimeout(() => {
                $('table').DataTable({
                    "searching": true,
                    "columnDefs": [
                        { "orderable": false, "targets": [1] }
                    ]
                });
                this.loading = false
            }, 1000)
        })
    }

    save(){
        this.rest.saveElement(this.item, 'TypeTerminal').subscribe(ata => {
            this.item = {
                name: ''
            }
            this.search()
        })
    }

    edit(item){
        this.item = item;
        $('html, body').animate({ scrollTop: $('#form').offset().top}, 1000);
    }

    remove(item){
        this.rest.removeElement(item.id, 'TypeTerminal').subscribe(data => {
            this.search()
        })
    }
}
